<template>
  <v-app :style="colorVapp">
    <onebox_toolbar></onebox_toolbar>
    <v-content>
      <v-card class="elevation-0">
        <v-divider></v-divider>
        <v-overlay :value="processloader" absolute :color="color.BG" dark>
          <v-progress-circular indeterminate size="64" :color="color.theme"></v-progress-circular>

          <span :style="colorProgress">&nbsp; &nbsp; loading</span>
        </v-overlay>
        <v-list nav dense :color="color.BG">
          <v-list-item class="text-left">
            <v-list-item-avatar class="text-center">
              <v-avatar :color="color.theme" size="35">
                <v-icon dark size="27">mdi-bag-checked</v-icon>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <span :style="headerPage">&nbsp; {{ $t("consentheader") }}</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
      <v-card class="elevation-0" :color="color.BG" id="thiscontainerlibrary">
        <v-card-text class="pa-0">
          <v-container fluid>
            <v-layout row wrap fill-height justify-center>
              <!-- <v-flex lg12 class="ma-2 ml-4 mr-4" v-if="loaddataprogress === true">
                  <div class="text-center">
                    <v-progress-circular :size="50" :color="color.theme" indeterminate></v-progress-circular>
                  </div>
                </v-flex> -->
              <v-flex lg12 class="ma-4">
                <!-- <v-layout row wrap fill-height  class="pl-2 pr-2"  >
               </v-layout> -->
                <!-- <br /> -->
                <!-- <v-layout v-if="resolutionScreen >= 500 ">
                    <v-flex xs4 lg4>
                    </v-flex>
                  
                  </v-layout> -->
                <!-- <v-layout row wrap class="pl-3 pr-3" v-else>
                    
                    -->
                <!-- </v-layout> -->
                <!-- หน้าจอxs -->

                <!-- <br /> -->
<!-- หน้าจอโทรศัพท์ -->
                <div v-if="resolutionScreen <= 500">
                  <!-- <div v-if="loaddataprogresstable === true "><v-flex lg12 class="ma-2 ml-4 mr-4" >
                  <div class="text-center">
                    <v-progress-circular :size="50" :color="color.theme" indeterminate></v-progress-circular>
                  </div>
                </v-flex></div> -->
                  <v-list two-line class="mb-6 pb-6 elevation-0" v-if="consentdata.length !==0">
                    <v-list-item v-for="item in consentdata" :key="item.title" style="cursor: pointer;">
                      <v-list-item-action>
                        <v-icon v-if="item.consent_status === '00'" :color="color.theme">mdi-email-alert-outline</v-icon>
                        <v-icon v-else color="grey">mdi-email-open-outline</v-icon>
                      </v-list-item-action>

                      <v-list-item-content>
                        <v-list-item-title >{{item.consent_name }}</v-list-item-title> 
                          <v-list-item-subtitle >{{item.consent_cre_dtm}}</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                          <v-btn v-if="item.consent_status !== '01' " color="green" dark small @click="fn_agree_consent(item)">{{ $t("consent.appove") }}</v-btn>
                          <v-btn v-if="item.consent_status !== '02' " color="red" dark small @click="fn_cancel_consent(item)">{{ $t("consent.cancelapprove") }}</v-btn>              
                        </v-list-item-action>
                    </v-list-item>

                    <v-divider inset></v-divider>
                  </v-list>
                  <v-list  class=" elevation-0" v-else>
                     <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title class="text-center" v-text="$t('tablefile.empty')"></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list> 
                </div>
<!-- หน้าจอคอม -->
                <div v-else>
                  <!-- <div v-if="loaddataprogresstable === true "><v-flex lg12 class="ma-2 ml-4 mr-4" >
                  <div class="text-center">
                    <v-progress-circular :size="50" :color="color.theme" indeterminate></v-progress-circular>
                  </div>
                </v-flex></div> -->
                  <!-- <div v-else> -->
                  <v-data-table
                    :no-data-text="$t('tablefile.empty')"
                    :headers="headers"
                    class="elevation-0 drag-selector pa-1"
                    :items="consentdata"
                    :hide-default-footer="true"
                    :items-per-page="consentdata.length"
                  >
                    <!-- <template v-slot:top>
                          <v-switch v-model="singleSelect" label="Single select" class="pa-3"></v-switch>
                      </template>-->
                    <template v-slot:[`header.consent_icon`]="{ header }">
                      <span class="pointer" :style="headerTable">{{ $t(header.text) }}</span>
                    </template>
                    <template v-slot:[`header.consent_date`]="{ header }">
                      <span class="pointer" :style="headerTable">{{ $t(header.text) }}</span>
                    </template>
                    <template v-slot:[`header.consent_name`]="{ header }">
                      <span class="pointer" :style="headerTable">{{ $t(header.text) }}</span>
                    </template>
                    <template v-slot:[`header.consent_detail`]="{ header }">
                      <span class="pointer" :style="headerTable">{{ $t(header.text) }}</span>
                    </template>
                    <template v-slot:[`header.consent_status`]="{ header }">
                      <span class="pointer" :style="headerTable">{{ $t(header.text) }}</span>
                    </template>
                    <!-- <template v-slot:header.date_effect="{ header }">
                        <span
                          class="pointer"
                          
                           :style="headerTable"
                        >{{ $t(header.text) }}</span>
                      </template> -->
                    <!-- <template v-slot:header.tag_version="{ header }">
                        <span
                          class="pointer"
                       
                           :style="headerTable"
                        >{{ $t(header.text) }}</span>
                      </template> -->
                    <!-- <template v-slot:header.file_status="{ header }">
                        <span 
                           :style="headerTable"
                        >{{ $t(header.text) }}</span>
                      </template> -->
                    <template v-slot:item="props">
                      <tr style="cursor: pointer">
                        <!-- <td width="7%" class="text-center">
                              <v-icon
                                large
                                :color="props.item.file_icon[1]"
                              >{{ props.item.file_icon[0] }}</v-icon>                       
                          </td> -->
                        
                        <!-- <td width="15%"  v-if="$t('default') === 'th'">{{ props.item.file_owner_th}}</td> -->
                        <td width="3%" v-if="props.item.consent_status === '00'" ><v-icon :color="color.theme">mdi-email-alert-outline</v-icon></td>
                        <td width="3%" v-else ><v-icon color="grey">mdi-email-open-outline</v-icon></td>
                        <!-- <td width="5%" v-else ><v-icon color="grey">mdi-email-remove</v-icon></td> -->
                        <td width="32%" class="text-center">{{ props.item.consent_name }}</td>
                          <td width="25%" class="text-center">{{ props.item.consent_cre_dtm}}</td>
                          <td width="15%" class="text-center"><v-btn color="grey" text small @click="fn_detail_consent(props.item)"
                            >{{ $t("consent.detail") }}</v-btn
                          ></td>
                        <!-- <td width="15%" class="text-center" v-text="props.item.consent_status_text === '00' ? $t('consent.pending') : props.item.consent_status_text == '01' ? $t('consent.approvetext') : $t('consent.canceltext') "></td> -->
                        <td width="15%" class="text-center" >
                          <v-chip v-if="props.item.consent_status_text === '01'" class="ma-2" color="green" outlined>{{ $t('consent.approvetext')}}</v-chip>
                          <v-chip v-else-if="props.item.consent_status_text === '00'" class="ma-2" color="grey" outlined>{{ $t('consent.pending')}}</v-chip>
                          <v-chip v-else class="ma-2" color="red" outlined>{{ $t('consent.canceltext')}}</v-chip>
                          </td>
                         <td width="15%" class="text-center">
                           <v-btn v-if="props.item.consent_status !== '01' " class="mr-1" color="green" dark small @click="fn_agree_consent(props.item)">{{ $t("consent.appove") }}</v-btn>
                          <v-btn v-if="props.item.consent_status !== '02' " color="red" dark small @click="fn_cancel_consent(props.item)">{{ $t("consent.cancelapprove") }}</v-btn>
                         </td>
                        <!-- <td width="10%" class="text-center"></td>
                          <td></td> -->
                      </tr>
                    </template>
                  </v-data-table>
                </div>
                <!-- </div> -->
                <!-- <infinite-loading @infinite="loadfile"></infinite-loading> -->
                <!-- <br />
                  <v-layout row wrap justify-center v-if="rootfile.length > 0">
                     <v-flex xs11 lg11 >
                      <v-pagination
                       :total-visible="10"
                        v-model="page"
                        :length="pageCount || 0"
                        :color="color.theme"
                        @input="clickpagination()"
                      ></v-pagination>
                    </v-flex> 
                  </v-layout>                -->
              </v-flex>
            </v-layout>
            <scroll-loader class="pa-0" :loader-method="fn_getconsent" :loader-disable="disablescrolling"> </scroll-loader>
            <div class="text-center" v-if="loaddataprogress === true">
              <v-progress-circular :size="50" :color="color.theme" indeterminate></v-progress-circular>
            </div>
          </v-container>
        </v-card-text>
      </v-card>
      <dialogdetail :dataconsent="dataconsent" :show="opendetailconsent" :key_show="keyshow" @close="opendetailconsent = false" @confirmcancel="fn_cancel"></dialogdetail>
    </v-content>
  </v-app>
</template>
<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import { mapState, mapGetters } from "vuex";
import InfiniteLoading from "vue-infinite-loading";
import "vue-full-screen-file-drop/dist/vue-full-screen-file-drop.css";
import VueFullScreenFileDrop from "vue-full-screen-file-drop";
import gbfGenerate from "@/globalFunctions/generateAuthorize";

const dialogdetail = () => import("../components/optional/dialog-detailconsent");
import onebox_toolbar from "../components/layout/layout-toolbar-new";
import formatDatetime from "../globalFunctions/formatDatetime";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});

export default {
  components: {
    onebox_toolbar,
    dialogdetail,
  },
  data: function() {
    return {
      dataconsent:{},
      code: "",
      keyshow: "",
      consentdata: [],
      loaddataprogress: false,
      disablescrolling: true,
      openconfrimcancel: false,
      opendetailconsent: false,
      headers: [  
        {
          text: "#",
          value: "consent_icon",
          width: "3%",
          
          sortable: false,
        },     
        {
          text: "consent.Name_",
          value: "consent_name",
          width: "32%",
           align: "left",
          sortable: false,
        },
        {
          text: "consent.date_time",
          value: "consent_date",
          width: "25%",
          align: "left",
          sortable: false,
        },
        {
          text: "consent.Detail",
          value: "consent_detail",
          width: "5%",
          align: "left",
          sortable: false,
        },
        {
          text: "consent.Status",
          value: "consent_status",
          width: "15%",
          align: "left",
          sortable: false,
        },
        {
          text: "consent.Edit",
          value: "consent_status",
          width: "20%",
          align: "left",
          sortable: false,
        },
      ],
    };
  },

  computed: {
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataAccountId",
    ]),
    countfolders() {
      let countfolder = this.foldernomal.length;
      return countfolder;
    },
    countfiles() {
      let countfile = this.filenomal.length;
      return countfile;
    },
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },

    paginatedData() {
      const start = (this.page - 1) * this.size;
      const end = start + this.size;
      return this.rootfile.slice(start, start + this.size);
    },
    headerTable() {
      return "color:" + this.color.theme + ";" + "font-weight: 600; font-size:15px;";
    },
    headerPage() {
      return "color:" + this.color.theme + ";" + "font-size: 18px; font-weight: 600;";
    },
    colorVapp() {
      return "background:" + this.color.BG + ";";
    },
    colorSort() {
      return "color:" + this.color.alertText + ";";
    },
    colorProgress() {
      return "color:" + this.color.theme + ";";
    },
  },
  methods: {
   //ดึงข้อมูลจาก api
    async fn_getconsent() {
      this.loaddataprogress = true;
      this.disablescrolling = true;
      this.consentdata = [];
      let tax =  this.dataAccountActive.taxid || ""
      var payload = {
        account_id: this.dataAccountId,
        taxid:tax
      };
      let auth = await gbfGenerate.generateToken();
      console.log("res",this.dataAccountActive);
     await this.axios.post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/check_all_consent", payload,{headers: { Authorization: auth.code },}).then((response) => {
        console.log("response", response);
        if (response.data.status === "OK") {
          for (let i = 0; i < response.data.result.length; i++) {
            let data = {};
            data["consent_cre_dtm"] = formatDatetime.formatdatetime(response.data.result[i]["consent_date"]);
            data["consent_detail"] = response.data.result[i]["consent_detail"];
            data["consent_name"] = response.data.result[i]["consent_name"];
            data["consent_status_text"] = response.data.result[i]["consent_status"];
           data["consent_status"] = response.data.result[i]["consent_status"]
           data["consent_id"] = response.data.result[i]["consent_id"];
            this.consentdata.push(data);
          }
          this.loaddataprogress = false;
          this.disablescrolling = true;
          console.log("hhhh", this.consentdata);
        }else{
          Toast.fire({
              icon: "error",
              title: "error",
            }); 
            this.loaddataprogress = false;   
            this.disablescrolling = true;     
        }
      }).catch((error) => {
           console.log(error);
          Toast.fire({
            icon: "error",
            title: this.$t("toast.cannotconnectonebox"),
          });
          this.loaddataprogress = false;
          this.disablescrolling = true;
        });
    },
    //กดยกเลิก consent จะมีpop up ให้ ยืนยันก่อน
    fn_cancel_consent(_item) {
      //คีย์บอกว่ากดปุ่มยกเลิก consent
      this.keyshow = "cancel";
      this.opendetailconsent = true;
      this.dataconsent = _item
    },
     //ยกเลิก consent
   async fn_cancel(_item) {
     var payload={      
          consent_id:_item.consent_id,
          consent_status: "02"      
      }
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/update_consent", payload,{headers: { Authorization: auth.code },})
        .then((response) => {
          console.log(response);
          if(response.data.status === "OK"){
            Toast.fire({
              icon: "success",
              title: "ยกเลิกสำเร็จ",
            });
            this.fn_getconsent();
          }else{
            Toast.fire({
              icon: "error",
              title: "ยกเลิกไม่สำเร็จ",
            });
          }
        }).catch((error) => {
          console.log(error);
          Toast.fire({
            icon: "error",
            title: this.$t("toast.cannotconnectonebox"),
          });
        });

    },
     //ยืนยัน consent
  async fn_agree_consent(_item) {
      this.loaddataprogress = true;
      console.log(_item);
      var payload={      
          consent_id:_item.consent_id,
          account_id: this.dataAccountId       
      }
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/consent_agree", payload,{headers: { Authorization: auth.code },})
        .then((response) => {
          console.log(response);
          if(response.data.status === "OK"){
            Toast.fire({
              icon: "success",
              title: "ยินยอมสำเร็จ",
            });
            this.loaddataprogress = false;
            this.fn_getconsent();
          }else{
            Toast.fire({
              icon: "error",
              title: "ยินยอมไม่สำเร็จ",
            });
            this.loaddataprogress = false;
          }
        }).catch((error) => {
         console.log(error);
         this.loaddataprogress = false;
          Toast.fire({
            icon: "error",
            title: this.$t("toast.cannotconnectonebox"),
          });
        });
    },
    //กดเพื่อเปิดดูรายละเอียด consent //เปิด dialog detailconsent
    fn_detail_consent(item) {
      this.dataconsent = []
      //คีย์บอกว่ากดปุ่มรายละเอียด consent
      this.keyshow = "detail";
      this.opendetailconsent = true;
      this.dataconsent = item
    },
  },
  mounted() {
     this.disablescrolling = false;
    this.loaddataprogress = false;
    this.fn_getconsent();
  },
};
</script>
<style></style>
